import { appQueryFactory } from '@api/api-slice';
import { EndpointUrlBuilder } from '@api/endpoints';
import { IPaginable, PaginationArgs } from '@features/pagination/paginable.interface';
import { validatePagination } from '@features/pagination/spec/pagination.spec';
import { TransportableModel } from '@models/transportable.type';
import { IEquipmentInvoiceSummary, IEquipmentInvoiceSummaryDTO } from '../equipment-invoice-summary.interface';
import { validateFilterEquipmentInvoiceSummary } from './spec/validate-equipment-invoice-summaries.spec';
import { ApiModel } from './form-config';
import { FinanceParams } from '@features/bulk-invoicing/finance-params.type';
import { enhanceWithInvoiceSummaryType } from '@features/bulk-invoicing/enhance-with-invoice-summary-type';

export type QueryParams = TransportableModel<ApiModel & PaginationArgs & FinanceParams>;
export type EquipmentInvoiceSummariesResponse = IPaginable<IEquipmentInvoiceSummary>;
export const buildGetEquipmentInvoiceSummaries = appQueryFactory<QueryParams, EquipmentInvoiceSummariesResponse>({
  query: (params) => ({
    url: EndpointUrlBuilder.getEquipmentInvoiceSummaries(),
    method: 'GET',
    params,
  }),
  transformResponse(response: IPaginable<IEquipmentInvoiceSummaryDTO>): IPaginable<IEquipmentInvoiceSummary> {
    validateFilterEquipmentInvoiceSummary(response.entries, EndpointUrlBuilder.getAppointmentInvoiceSummaries());
    validatePagination(response, EndpointUrlBuilder.getAppointmentInvoiceSummaries());
    return {
      pagination: response.pagination,
      entries: response.entries.map((entry) =>
        enhanceWithInvoiceSummaryType<IEquipmentInvoiceSummary>(entry, 'Equipment'),
      ),
    };
  },
});
