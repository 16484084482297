import React from 'react';
import { Box, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { EmptyCell } from '@utils/empty-cell';
import { isNil } from 'lodash';
import { BooleanChip } from '@utils/render-boolean';

export type DetailsConfig<Model> = {
  [K in keyof Model]: {
    label: string;
    render?: (value: Model[K]) => JSX.Element | string | null;
  };
};

type ValidModel = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

type Props<Model> = {
  model: Model;
  config: DetailsConfig<Model>;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const defaultRender = (value: any): JSX.Element | string => {
  if (typeof value === 'boolean') {
    return <BooleanChip value={value} />;
  }
  return value?.toString() ?? EmptyCell;
};

export const AppDetails: <Model extends ValidModel>(props: Props<Model>) => JSX.Element = ({ model, config }) => {
  return (
    <TableContainer component={Box} overflow="visible">
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableBody>
          {Object.entries(config).map(([key, { label, render = defaultRender }]) => (
            <TableRow key={key}>
              <TableCell component="th" scope="row" sx={{ minWidth: '300px' }}>
                {label}
              </TableCell>
              <TableCell component="td" scope="row" sx={{ width: '100%' }}>
                {isNil(model[key]) ? EmptyCell : render(model[key])}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
