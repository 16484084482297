import {
  useBulkAddAppointmentSupplierInvoiceMutation,
  useBulkAddSessionSupplierInvoiceMutation,
  useBulkGenerateAnnualSupportChargeXeroInvoiceMutation,
  useBulkGenerateAppointmentXeroInvoiceMutation,
  useBulkGenerateEquipmentXeroInvoiceMutation,
  useBulkGenerateSessionXeroInvoiceMutation,
  useBulkSendAnnualSupportChargeInvoiceMutation,
  useBulkSendAppointmentInvoiceMutation,
  useBulkSendEquipmentInvoiceMutation,
  useBulkSendSessionInvoiceMutation,
} from '@api/api-slice';
import { BulkActionTypes } from './bulk-action-type.type';
import { InvoiceSummaryType, InvoiceSummaryTypes } from './invoice-summary-type.type';
import { BulkAddSupplierInvoicePayload, BulkUpdatePayload, UseBulkActionMutation } from './types';

type MutationsByBulkAction = {
  [BulkActionTypes.AddSupplierInvoice]: UseBulkActionMutation<BulkAddSupplierInvoicePayload> | null;
  [BulkActionTypes.GenerateXeroInvoice]: UseBulkActionMutation<BulkUpdatePayload>;
  [BulkActionTypes.SendEInvoice]: UseBulkActionMutation<BulkUpdatePayload>;
};

export type MutationsByInvoiceSummaryAndBulkAction = {
  [K in InvoiceSummaryType]: MutationsByBulkAction;
};

export const MutationsByInvoiceSummaryAndBulkAction: MutationsByInvoiceSummaryAndBulkAction = {
  [InvoiceSummaryTypes.AnnualSupportCharge]: {
    [BulkActionTypes.AddSupplierInvoice]: null,
    [BulkActionTypes.GenerateXeroInvoice]: useBulkGenerateAnnualSupportChargeXeroInvoiceMutation,
    [BulkActionTypes.SendEInvoice]: useBulkSendAnnualSupportChargeInvoiceMutation,
  },
  [InvoiceSummaryTypes.Appointment]: {
    [BulkActionTypes.AddSupplierInvoice]: useBulkAddAppointmentSupplierInvoiceMutation,
    [BulkActionTypes.GenerateXeroInvoice]: useBulkGenerateAppointmentXeroInvoiceMutation,
    [BulkActionTypes.SendEInvoice]: useBulkSendAppointmentInvoiceMutation,
  },
  [InvoiceSummaryTypes.Equipment]: {
    [BulkActionTypes.AddSupplierInvoice]: null,
    [BulkActionTypes.GenerateXeroInvoice]: useBulkGenerateEquipmentXeroInvoiceMutation,
    [BulkActionTypes.SendEInvoice]: useBulkSendEquipmentInvoiceMutation,
  },
  [InvoiceSummaryTypes.Session]: {
    [BulkActionTypes.AddSupplierInvoice]: useBulkAddSessionSupplierInvoiceMutation,
    [BulkActionTypes.GenerateXeroInvoice]: useBulkGenerateSessionXeroInvoiceMutation,
    [BulkActionTypes.SendEInvoice]: useBulkSendSessionInvoiceMutation,
  },
} as const;
