import { appQueryFactory } from '@api/api-slice';
import { EndpointUrlBuilder } from '@api/endpoints';
import { IPaginable, PaginationArgs } from '@features/pagination/paginable.interface';
import { validatePagination } from '@features/pagination/spec/pagination.spec';
import { TransportableModel } from '@models/transportable.type';
import {
  IAnnualSupportChargeInvoiceSummary,
  IAnnualSupportChargeInvoiceSummaryDTO,
} from '../annual-support-charge-invoice-summary.interface';
import { validateFilterAnnualSupportChargeInvoiceSummary } from './spec/validate-annual-support-charge-invoice-summaries.spec';
import { ApiModel } from './form-config';
import { enhanceWithInvoiceSummaryType } from '@features/bulk-invoicing/enhance-with-invoice-summary-type';

export type QueryParams = TransportableModel<ApiModel & PaginationArgs>;
export type AnnualSupportChargeInvoiceSummariesResponse = IPaginable<IAnnualSupportChargeInvoiceSummary>;
export const buildGetAnnualSupportChargeInvoiceSummaries = appQueryFactory<
  QueryParams,
  AnnualSupportChargeInvoiceSummariesResponse
>({
  query: (params) => ({
    url: EndpointUrlBuilder.getAnnualSupportChargeInvoiceSummaries(),
    method: 'GET',
    params,
  }),
  transformResponse(
    response: IPaginable<IAnnualSupportChargeInvoiceSummaryDTO>,
  ): IPaginable<IAnnualSupportChargeInvoiceSummary> {
    validateFilterAnnualSupportChargeInvoiceSummary(
      response.entries,
      EndpointUrlBuilder.getEquipmentInvoiceSummaries(),
    );
    validatePagination(response, EndpointUrlBuilder.getEquipmentInvoiceSummaries());
    return {
      pagination: response.pagination,
      entries: response.entries.map((entry) =>
        enhanceWithInvoiceSummaryType<IAnnualSupportChargeInvoiceSummary>(entry, 'AnnualSupportCharge'),
      ),
    };
  },
});
