import { useSaveAnnualSupportChargeMutation } from '@api/api-slice';
import { IAnnualSupportCharge } from './annual-support-charge.interface';
import { RequestStatusFlags } from '@utils/merge-request-status-flags';
import { isErrorResponse } from '@api/error-response.interface';
import { useAppDispatch } from '@store/use-app-dispatch';
import { AnnualSupportChargeActions } from './annual-support-charge.slice';

type HookResult = {
  saveAnnualSupportChargeElectronicInvoice: (value: string) => Promise<void>;
} & RequestStatusFlags;

export function useSaveAnnualSupportChargeElectronicInvoice(
  annualSupportCharge: IAnnualSupportCharge,
  orderId: number,
): HookResult {
  const [mutation, flags] = useSaveAnnualSupportChargeMutation();
  const dispatch = useAppDispatch();

  const saveAnnualSupportChargeElectronicInvoice = async (electronicInvoice: string | null): Promise<void> => {
    const data = {
      orderId,
      annualSupportChargeId: annualSupportCharge.id,
      payload: {
        ...annualSupportCharge,
        electronicInvoice,
      },
    };

    const result = await mutation(data).unwrap();
    if (isErrorResponse(result)) {
      return;
    }
    dispatch(AnnualSupportChargeActions.upsertOne(result));
  };

  return {
    saveAnnualSupportChargeElectronicInvoice,
    ...flags,
  };
}
