import { appMutationFactory, appQueryFactory } from '@api/api-slice';
import { IAnnualSupportCharge } from './annual-support-charge.interface';
import { EndpointUrlBuilder } from '@api/endpoints';
import { IErrorResponse } from '@api/error-response.interface';
import { IElectronicInvoiceLog } from '@features/electronic-invoice/electronic-invoice-log.interface';

type AnnualSupportChargeListResponse = {
  annualSupportCharges: IAnnualSupportCharge[];
};

export const buildGetAnnualSupportChargeList = appQueryFactory<number, AnnualSupportChargeListResponse>({
  query: (orderId) => ({
    url: EndpointUrlBuilder.getAnnualSupportChargeList(orderId),
    method: 'GET',
  }),
});

export const buildRefreshAnnualSupportChargeElectronicInvoice = appMutationFactory<number, string | IErrorResponse>({
  query: (id) => ({
    url: EndpointUrlBuilder.refreshAnnualSupportChargeElectronicInvoice(id),
    method: 'POST',
  }),
});

type SendElectronicInvoiceResponse = { electronicInvoiceLogs: IElectronicInvoiceLog[] } | IErrorResponse;

export const buildSendAnnualSupportChargeElectronicInvoice = appMutationFactory<number, SendElectronicInvoiceResponse>({
  query: (id) => ({
    url: EndpointUrlBuilder.sendAnnualSupportChargeElectronicInvoice(id),
    method: 'POST',
  }),
});

type GenerateXeroInvoiceResponse = { electronicInvoiceLogs: IElectronicInvoiceLog[] } | IErrorResponse;

export const buildGenerateAnnualSupportChargeElectronicInvoice = appMutationFactory<
  number,
  GenerateXeroInvoiceResponse
>({
  query: (id) => ({
    url: EndpointUrlBuilder.generateAnnualSupportChargeElectronicInvoice(id),
    method: 'POST',
  }),
});
