import { GetTypeByBackendLabelKey } from '@features/backend-label/backend-label.type';
import { IAppointment } from '@features/order/needs-assessment/appointment/appointment.interface';
import { Money } from '@utils/money/money.type';
import { InvoiceSummaryTypes } from '../invoice-summary-type.type';
import { IInvoiceSummary } from '../invoice-summary.interface';

type SharedAppointmentFields = Pick<
  IAppointment,
  | 'id'
  | 'orderId'
  | 'customerId'
  | 'assessmentMethod'
  | 'appointmentDate'
  | 'isCancelled'
  | 'cancellationReason'
  | 'isPaid'
  | 'funderInvoice'
  | 'supplierInvoice'
>;

export interface IAppointmentInvoiceSummary extends SharedAppointmentFields {
  invoiceSummaryType: typeof InvoiceSummaryTypes.Appointment;
  customerFullName: string;
  customerCrn: string;
  orderStatusId: GetTypeByBackendLabelKey<'statusOptions'>;
  dipReference: string;
  organisationId: GetTypeByBackendLabelKey<'organisationOptions'>;
  exVat: Money;
}

export type IAppointmentInvoiceSummaryDTO = Omit<IAppointmentInvoiceSummary, 'invoiceSummaryType'>;

export function isAppointmentInvoice(invoice: IInvoiceSummary): invoice is IAppointmentInvoiceSummary {
  return invoice.invoiceSummaryType === InvoiceSummaryTypes.Appointment;
}
