import { appMutationFactory } from '@api/api-slice';
import { EndpointUrlBuilder } from '@api/endpoints';
import { modelToSnakeCase } from '@utils/model-to-snake-case';
import { BulkAddSupplierInvoicePayload, BulkUpdatePayload, IBulkUpdateResponse } from '../types';

export const buildBulkAddAppointmentSupplierInvoice = appMutationFactory<
  BulkAddSupplierInvoicePayload,
  IBulkUpdateResponse
>({
  query: (payload) => ({
    url: EndpointUrlBuilder.bulkAddAppointmentSupplierInvoice(),
    method: 'POST',
    data: modelToSnakeCase(payload),
  }),
});

export const buildBulkGenerateAppointmentXeroInvoice = appMutationFactory<BulkUpdatePayload, IBulkUpdateResponse>({
  query: (payload) => ({
    url: EndpointUrlBuilder.bulkGenerateAppointmentXeroInvoice(),
    method: 'POST',
    data: modelToSnakeCase(payload),
  }),
});

export const buildBulkSendAppointmentInvoice = appMutationFactory<BulkUpdatePayload, IBulkUpdateResponse>({
  query: (payload) => ({
    url: EndpointUrlBuilder.bulkSendAppointmentInvoice(),
    method: 'POST',
    data: modelToSnakeCase(payload),
  }),
});
