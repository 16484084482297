import React from 'react';
import { NavLink } from 'react-router-dom';
import { Link } from '@mui/material';
import { BackendLabelGroup } from '@features/backend-label/BackendLabelGroup';
import { Routes } from '@routes/routes';
import { createColumnFactory } from '@utils/create-column.factory';
import { BooleanChip } from '@utils/render-boolean';
import { formatPercent } from '@utils/format-percent';
import { TutorSummary } from './tutor.interface';
import { renderEnum } from '@utils/enum/render-enum';
import { EmploymentTypeOptions } from './employment-type.type';
import { BackendLabel } from '@features/backend-label/BackendLabel';
import { EmptyCell } from '@utils/empty-cell';

export const createTutorColumn = createColumnFactory<TutorSummary>();

export const tutorSummariesColumn = [
  createTutorColumn('id', {
    headerName: 'ID',
    width: 45,
    renderCell: ({ row }) => (
      <Link key={row.id} component={NavLink} to={`${Routes.tutors}/${row.id}`}>
        {row.id}
      </Link>
    ),
  }),
  createTutorColumn('firstName', {
    headerName: 'Name',
    flex: 1,
    renderCell: ({ row }) => `${row.firstName || ''} ${row.lastName || ''}`,
  }),
  createTutorColumn('isActive', {
    headerName: 'Active',
    width: 75,
    renderCell: ({ row }) => <BooleanChip value={row.isActive} />,
  }),
  createTutorColumn('organisationId', {
    headerName: 'Organisation',
    width: 100,
    renderCell: ({ row }) => <BackendLabel value={row.organisationId} optionKey="organisationOptions" />,
  }),
  createTutorColumn('employmentType', {
    headerName: 'Status',
    width: 100,
    renderCell: ({ row }) => renderEnum(row.employmentType, EmploymentTypeOptions),
  }),
  createTutorColumn('qualification', {
    headerName: 'Support Type',
    flex: 2,
    renderCell: ({ row }) => <BackendLabelGroup labels={row.qualification} optionKey="qualificationOptions" />,
  }),
  createTutorColumn('completionRate', {
    headerName: 'Completion Rate',
    flex: 1,
    align: 'right',
    headerAlign: 'right',
    cellClassName: 'number',
    renderCell: ({ row }) => formatPercent(row.completionRate),
  }),
  createTutorColumn('cancellationRate', {
    headerName: 'Cancellation Rate',
    flex: 1,
    align: 'right',
    headerAlign: 'right',
    cellClassName: 'number',
    renderCell: ({ row }) => formatPercent(row.cancellationRate),
  }),
  createTutorColumn('hoursDelivered', {
    headerName: 'H. Delivered',
    description: 'Hours Delivered',
    width: 86,
    align: 'right',
    headerAlign: 'right',
    cellClassName: 'number',
  }),
  createTutorColumn('hoursCancelled', {
    headerName: 'H. Cancelled',
    description: 'Hours Cancelled',
    width: 86,
    align: 'right',
    headerAlign: 'right',
    cellClassName: 'number',
  }),
  createTutorColumn('unsignedSessionsCount', {
    headerName: 'Unsigned',
    description: 'Unsigned Sessions Count',
    width: 75,
    align: 'right',
    headerAlign: 'right',
    cellClassName: 'number',
  }),
  createTutorColumn('activeSupportAssigned', {
    headerName: 'Assigned',
    description: 'Active Support Assigned',
    width: 75,
    align: 'right',
    headerAlign: 'right',
    cellClassName: 'number',
  }),
  createTutorColumn('feedbackAverageTotal', {
    headerName: 'Feedback',
    description: 'Feedback Average Total',
    width: 90,
    align: 'right',
    headerAlign: 'right',
    cellClassName: 'number',
    renderCell: ({ row }) =>
      row.feedbackAverageTotal !== null ? Number(row.feedbackAverageTotal).toFixed(4) : EmptyCell,
  }),
];
