import { useAppDispatch } from '@store/use-app-dispatch';
import { AnnualSupportChargeActions } from './annual-support-charge.slice';
import { useRefreshAnnualSupportChargeElectronicInvoiceMutation } from '@api/api-slice';
import { isErrorResponse } from '@api/error-response.interface';
import { RequestStatusFlags } from '@utils/merge-request-status-flags';

type HookResult = {
  refreshAnnualSupportChargeElectronicInvoice: (id: number) => Promise<void>;
} & RequestStatusFlags;

export function useRefreshAnnualSupportChargeElectronicInvoice(): HookResult {
  const [refreshMutation, flags] = useRefreshAnnualSupportChargeElectronicInvoiceMutation();
  const dispatch = useAppDispatch();

  const refreshAnnualSupportChargeElectronicInvoice = async (id: number): Promise<void> => {
    const electronicInvoice = await refreshMutation(id).unwrap();
    if (!isErrorResponse(electronicInvoice)) {
      dispatch(AnnualSupportChargeActions.updateOne({ id, changes: { electronicInvoice } }));
    }
  };

  return { refreshAnnualSupportChargeElectronicInvoice, ...flags };
}
