import { keyBy, mapValues } from 'lodash';
import { PayloadAction, createDraftSafeSelector, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { RootState } from '@store/store';
import { IInvoiceSummary } from './invoice-summary.interface';
import { BulkActionRowResult, IInvoiceSummaryWithResult, Result } from './types';

const invoiceSummaryAdapter = createEntityAdapter<IInvoiceSummary>({
  selectId: (entry) => entry.id,
});

const genericInvoiceSummarySlice = createSlice({
  name: 'Generic Invoice Summary',
  initialState: invoiceSummaryAdapter.getInitialState({
    activeIds: [] as number[],
    results: {} as Record<number, Result>,
  }),
  reducers: {
    setAllOfType: invoiceSummaryAdapter.setAll,
    updateMany: invoiceSummaryAdapter.updateMany,
    removeAll: invoiceSummaryAdapter.removeAll,
    setActiveIds: (state, action: PayloadAction<number[]>) => {
      state.activeIds = action.payload;
    },
    clearActiveIds: (state) => {
      state.activeIds = [];
    },
    setResults: (state, action: PayloadAction<BulkActionRowResult[]>) => {
      state.results = mapValues(keyBy(action.payload, 'id'), 'result') as Record<number, Result>;
    },
    clearResults: (state) => {
      state.results = {};
    },
  },
});

export const GenericInvoiceSummaryActions = genericInvoiceSummarySlice.actions;
export const genericInvoiceSummaryReducer = genericInvoiceSummarySlice.reducer;
export type GenericInvoiceSummaryActionsType = typeof GenericInvoiceSummaryActions;

export const { selectAll } = invoiceSummaryAdapter.getSelectors((state: RootState) => state.genericInvoiceSummaries);

export const selectActiveIds = (state: RootState): number[] => state.genericInvoiceSummaries.activeIds;
export const selectResults = (state: RootState): Record<number, Result> => state.genericInvoiceSummaries.results;

export const selectActive = createDraftSafeSelector(selectAll, selectActiveIds, (all, activeIds): IInvoiceSummary[] =>
  all.filter((entry) => activeIds.includes(entry.id)),
);

export const selectActiveWithResults = createDraftSafeSelector(
  selectActive,
  selectResults,
  (activeSummaries, results): IInvoiceSummaryWithResult[] =>
    activeSummaries.map((entry) => ({ ...entry, result: results[entry.id] ?? null })),
);
