import { useSendAnnualSupportChargeElectronicInvoiceMutation } from '@api/api-slice';
import { AnnualSupportChargeActions } from './annual-support-charge.slice';
import { useAppDispatch } from '@store/use-app-dispatch';
import { RequestStatusFlags } from '@utils/merge-request-status-flags';
import { isErrorResponse } from '@api/error-response.interface';

type HookResult = {
  sendAnnualSupportChargeElectronicInvoice: (id: number) => Promise<void>;
} & RequestStatusFlags;

export function useSendAnnualSupportChargeElectronicInvoice(): HookResult {
  const [sendMutation, flags] = useSendAnnualSupportChargeElectronicInvoiceMutation();
  const dispatch = useAppDispatch();

  const sendAnnualSupportChargeElectronicInvoice = async (id: number): Promise<void> => {
    const changes = await sendMutation(id).unwrap();
    if (!isErrorResponse(changes)) {
      dispatch(
        AnnualSupportChargeActions.updateOne({
          id,
          changes,
        }),
      );
    }
  };

  return { sendAnnualSupportChargeElectronicInvoice, ...flags };
}
