import React from 'react';
import { Chip, ChipProps, Tooltip } from '@mui/material';
import { EmptyCell } from '@utils/empty-cell';
import { Result } from './types';

type Props = {
  result: Result | null;
};

export const ResultCell: React.FC<Props> = ({ result }) => {
  if (!result) {
    return <span>{EmptyCell}</span>;
  }

  const { isSuccess, message } = result;

  const label = result.isSuccess ? 'Accepted' : 'Rejected';

  const color = ((): ChipProps['color'] => {
    if (isSuccess === false) {
      return 'error';
    }
    if (message) {
      return 'warning';
    }
    return 'success';
  })();

  if (message) {
    return (
      <Tooltip title={message}>
        <Chip
          label={label}
          size="small"
          variant="outlined"
          color={color}
          className="MuiChip-status MuiChip-multiline"
        />
      </Tooltip>
    );
  }

  return (
    <Chip label={label} size="small" variant="outlined" color={color} className="MuiChip-status MuiChip-multiline" />
  );
};
