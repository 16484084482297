import { InvoiceSummaryType } from './invoice-summary-type.type';
import { IInvoiceSummary } from './invoice-summary.interface';

// TODO remove me after back return type in responses
export function enhanceWithInvoiceSummaryType<T extends IInvoiceSummary>(
  invoiceSummary: Omit<T, 'invoiceSummaryType'>,
  type: InvoiceSummaryType,
): T {
  return { ...invoiceSummary, invoiceSummaryType: type } as T;
}
