import { useGenerateAnnualSupportChargeElectronicInvoiceMutation } from '@api/api-slice';
import { useAppDispatch } from '@store/use-app-dispatch';
import { RequestStatusFlags } from '@utils/merge-request-status-flags';
import { AnnualSupportChargeActions } from './annual-support-charge.slice';
import { isErrorResponse } from '@api/error-response.interface';

type HookResult = {
  generateAnnualSupportChargeElectronicInvoice: (id: number) => Promise<void>;
} & RequestStatusFlags;

export function useGenerateAnnualSupportChargeElectronicInvoice(): HookResult {
  const [mutation, flags] = useGenerateAnnualSupportChargeElectronicInvoiceMutation();
  const dispatch = useAppDispatch();

  const generateAnnualSupportChargeElectronicInvoice = async (id: number): Promise<void> => {
    const changes = await mutation(id).unwrap();
    if (!isErrorResponse(changes)) {
      dispatch(
        AnnualSupportChargeActions.updateOne({
          id,
          changes,
        }),
      );
    }
  };

  return { generateAnnualSupportChargeElectronicInvoice, ...flags };
}
