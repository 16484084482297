import { Describe, StructError, assert, boolean, nullable, number, object, pattern, string } from 'superstruct';
import { IAnnualSupportChargeInvoiceSummaryDTO } from '@features/bulk-invoicing/annual-support-charge/annual-support-charge-invoice-summary.interface';
import { ISODateRegexp } from '@utils/dates/iso-string.type';
import { handleFailure } from '@utils/superstruct/handle-failure';
import { MoneyStruct } from '@utils/superstruct/money.struct';

const AnnualSupportChargeInvoiceSummaryStruct: Describe<IAnnualSupportChargeInvoiceSummaryDTO> = object({
  id: number(),
  orderId: number(),
  customerId: number(),
  equipmentTypeId: number(),
  annualSupportExVat: MoneyStruct,
  annualSupportInclVat: MoneyStruct,
  billingDate: pattern(string(), ISODateRegexp),
  isCancelled: boolean(),
  cancellationReason: nullable(string()),
  funderInvoice: nullable(string()),
  dipReference: nullable(string()),
  isPaid: boolean(),
  numberOfSupportYear: number(),
});

export function validateFilterAnnualSupportChargeInvoiceSummary(
  entries: IAnnualSupportChargeInvoiceSummaryDTO[],
  url: string,
): void {
  for (const entry of entries) {
    try {
      assert(entry, AnnualSupportChargeInvoiceSummaryStruct);
    } catch (error) {
      for (const failure of (error as StructError).failures()) {
        handleFailure(failure, url);
      }
    }
  }
}
