import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { GenericInvoiceSummaryActions } from './generic-invoice-summary.slice';

export const BulkInvoicingPage: React.FC = () => {
  useEffect(() => {
    return () => {
      GenericInvoiceSummaryActions.removeAll();
    };
  }, []);
  return <Outlet />;
};
