import { Moment } from 'moment-timezone';
import { sanitizeFactory } from '@utils/forms/sanitize.factory';
import { serializeFactory } from '@utils/forms/serialize.factory';
import { ISODateString } from '@utils/dates/iso-string.type';
import { Serializers } from '@utils/forms/serializers';

export type FormModel = {
  billingDateFrom: Moment | null;
  billingDateTo: Moment | null;
  equipmentTypeId: Array<number | null>;
  numberOfSupportYear: string;
  funderInvoice: string;
  dipReference: string;
  isCancelled: boolean | null;
};

export type ApiModel = {
  billingDateFrom: ISODateString | null;
  billingDateTo: ISODateString | null;
  equipmentTypeId: Array<number | null>;
  numberOfSupportYear: string | null;
  funderInvoice: string | null;
  dipReference: string | null;
  isCancelled: boolean | null;
};

export const sanitize = sanitizeFactory<ApiModel, FormModel>({
  billingDateFrom: { type: 'date', initial: null },
  billingDateTo: { type: 'date', initial: null },
  equipmentTypeId: { type: 'backend-label', optionKey: 'organisationOptions', multiple: true, initial: [] },
  numberOfSupportYear: { type: 'transportable', initial: '' },
  funderInvoice: { type: 'transportable', initial: '' },
  dipReference: { type: 'transportable', initial: '' },
  isCancelled: { type: 'transportable', initial: null },
});

export const serialize = serializeFactory<ApiModel, FormModel>({
  billingDateFrom: Serializers.date,
  billingDateTo: Serializers.date,
  equipmentTypeId: Serializers.array,
  numberOfSupportYear: Serializers.stringNullable,
  funderInvoice: Serializers.stringNullable,
  dipReference: Serializers.stringNullable,
  isCancelled: Serializers.booleanNullable,
});
