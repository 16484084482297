import { useGetZohoFreeSlotsQuery } from '@api/api-slice';
import { isErrorResponse } from '@api/error-response.interface';
import { RequestStatusFlags } from '@utils/merge-request-status-flags';
import { useEffect, useMemo, useState } from 'react';
import { GroupedByDay, groupSlotsByDay } from './group-slots-by-date';
import { ZohoSlotsQuery } from './api';
import { splitSlotsByDuration } from './split-slots-by-duration';
import { splitSlotsByTimeIntervals } from './split-slots-by-time-intervals';
import moment, { Moment } from 'moment-timezone';
import { getMonthRange } from './get-month-range';
import { ISlot } from './slot.interface';
import { createSlotByStartAndDuration } from './create-slot-by-start-and-duration';

type HookResult = RequestStatusFlags & {
  groupedByDay: GroupedByDay;
  offeredSlot: ISlot | null;
  handleMonthChange: React.Dispatch<React.SetStateAction<Moment>>;
};

/**
 * @param {number} duration in hours
 */
export function useZohoFreeSlots(params: ZohoSlotsQuery, duration: number, isASThardcoded = false): HookResult {
  const now = useMemo(() => moment(), []);
  const [fromMoment, setFromMoment] = useState<Moment>(now);
  const dateRange = getMonthRange(fromMoment, now);
  const shouldSkip = moment(dateRange.to).isBefore(now);
  const { data, ...flags } = useGetZohoFreeSlotsQuery(
    { ...params, ...dateRange },
    {
      refetchOnMountOrArgChange: true,
      skip: shouldSkip,
    },
  );
  const [groupedByDay, setGroupedByDay] = useState<GroupedByDay>({});
  const [offeredSlot, setOfferedSlot] = useState<ISlot | null>(null);

  useEffect(() => {
    if (data && !isErrorResponse(data)) {
      // actually all this things (grouping and splitting) is backend work,
      // but unfortunately they can't do this right now
      const { freeSlots, offeredDate } = data;
      const splitted = isASThardcoded
        ? splitSlotsByTimeIntervals(freeSlots)
        : splitSlotsByDuration(freeSlots, duration);
      setGroupedByDay(groupSlotsByDay(splitted));
      // this is business logic, we are saving the first offered date
      // actually the first fetched slot in current month
      if (offeredSlot === null && offeredDate) {
        setOfferedSlot(createSlotByStartAndDuration(offeredDate, duration));
      }
    }

    return () => {
      setGroupedByDay({});
    };
  }, [data, duration, isASThardcoded, offeredSlot]);

  return { groupedByDay, offeredSlot, handleMonthChange: setFromMoment, ...flags };
}
