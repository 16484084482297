import React, { useState } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { GridPagination, GridRowSelectionModel, GridSelectedRowCount } from '@mui/x-data-grid';
import { AppDataGrid } from '@components/AppDataGrid';
import { AppPaper } from '@components/AppPaper';
import { PageSizeNames } from '@features/pagination/page-size-names';
import { useServerPagination } from '@features/pagination/use-server-pagination';
import { QuickFilters } from '@features/quick-filter-tabs/QuickFilters';
import { QuickFilterTabsTypes } from '@features/quick-filter-tabs/quick-filter-tabs.type';
import { useAppTitle } from '@hooks/use-app-title';
import { useCachedState } from '@hooks/use-cached-state';
import { moneyToNumber } from '@utils/money/money-to-number';
import { renderMoney } from '@utils/money/render-money';
import { useAppDispatch } from '@store/use-app-dispatch';
import { useAppSelector } from '@store/use-app-selector';
import { BulkInvoicingDrawer } from '../BulkInvoicingDrawer';
import { BulkActionTypeType, BulkActionTypes } from '../bulk-action-type.type';
import { GenericInvoiceSummaryActions, selectActiveIds } from '../generic-invoice-summary.slice';
import { InvoiceSummaryTypes } from '../invoice-summary-type.type';
import { useFilteredAppointmentInvoiceSummaries } from './filter-appointment-invoice-summaries/use-filtered-appointment-invoice-summaries';
import { FilterAppointmentInvoiceSummaries } from './filter-appointment-invoice-summaries/FilterAppointmentInvoiceSummaries';
import { appointmentInvoiceColumns, appointmentInvoiceColumnsWithResult } from './appointment-invoice-columns';

export const BulkAppointmentInvoicingPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const selection = useAppSelector((state) => selectActiveIds(state));
  const [isQuickFilterActionAllowed, setIsQuickFilterActionAllowed] = useCachedState(false);
  const [activeBulkActionType, setActiveBulkActionType] = useState<BulkActionTypeType | null>(null);
  useAppTitle('Bulk Appointment Invoicing');
  const { entries, totalEntries, paginationModel, onPaginationModelChange, isLoading } = useServerPagination(
    useFilteredAppointmentInvoiceSummaries,
    PageSizeNames.AppointmentInvoiceSummary,
  );

  const isButtonDisabled = !isQuickFilterActionAllowed || selection.length === 0;
  const selectedRows = entries.filter((item) => selection.includes(item.id));
  const totalGrossCost = selectedRows.reduce((a, c) => a + moneyToNumber(c.exVat), 0);

  const closeDrawer = (): void => setActiveBulkActionType(null);

  const handleChangeSelection = (selection: GridRowSelectionModel): void => {
    dispatch(GenericInvoiceSummaryActions.setActiveIds(selection as number[]));
  };

  return (
    <AppPaper sx={{ display: 'flex', flexDirection: 'column' }}>
      <Typography variant="h4" component="h1">
        Finance Dashboard — Assessment Appointments Invoicing
      </Typography>
      <QuickFilters onChange={setIsQuickFilterActionAllowed} type={QuickFilterTabsTypes.Appointments}>
        <FilterAppointmentInvoiceSummaries />
      </QuickFilters>
      <AppDataGrid
        rows={entries}
        columns={appointmentInvoiceColumns}
        loading={isLoading}
        checkboxSelection={isQuickFilterActionAllowed}
        onRowSelectionModelChange={handleChangeSelection}
        rowSelectionModel={selection}
        disableRowSelectionOnClick={!isQuickFilterActionAllowed}
        hideFooter={false}
        paginationMode="server"
        rowCount={totalEntries}
        paginationModel={paginationModel}
        onPaginationModelChange={onPaginationModelChange}
        slots={{
          footer: () => (
            <Stack
              direction="row"
              sx={{ borderTop: '1px solid rgb(224, 224, 224)', px: 2, py: 1 }}
              spacing={2}
              alignItems="center"
            >
              <Button
                onClick={(): unknown => setActiveBulkActionType(BulkActionTypes.AddSupplierInvoice)}
                disabled={isButtonDisabled}
                variant="outlined"
              >
                Add Supplier Invoice
              </Button>
              <Button
                onClick={(): unknown => setActiveBulkActionType(BulkActionTypes.GenerateXeroInvoice)}
                disabled={isButtonDisabled}
                variant="outlined"
              >
                Generate Xero Invoices
              </Button>
              <Button
                onClick={(): unknown => setActiveBulkActionType(BulkActionTypes.SendEInvoice)}
                disabled={isButtonDisabled}
                variant="outlined"
              >
                Send E-Invoices
              </Button>
              {selection.length > 0 && (
                <>
                  <GridSelectedRowCount selectedRowCount={selection.length} />
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>{`${renderMoney(totalGrossCost)} Total`}</Box>
                </>
              )}
              <GridPagination sx={{ ml: 'auto !important' }} />
            </Stack>
          ),
        }}
        sx={{ mt: 2 }}
      />
      <BulkInvoicingDrawer
        bulkActionType={activeBulkActionType}
        invoiceSummaryType={InvoiceSummaryTypes.Appointment}
        onClose={closeDrawer}
        columns={appointmentInvoiceColumnsWithResult}
      />
    </AppPaper>
  );
};
