import { useGetAnnualSupportChargeListQuery } from '@api/api-slice';
import { RequestStatusFlags } from '@utils/merge-request-status-flags';
import { IAnnualSupportCharge } from './annual-support-charge.interface';
import { useAppDispatch } from '@store/use-app-dispatch';
import { useEffect } from 'react';
import { AnnualSupportChargeActions, selectAllAnnualSupportCharge } from './annual-support-charge.slice';
import { useAppSelector } from '@store/use-app-selector';

type HookResult = RequestStatusFlags & {
  annualSupportChargeList: IAnnualSupportCharge[];
};

export const useGetAnnualSupportCharge = (orderId: number): HookResult => {
  const { data, ...flags } = useGetAnnualSupportChargeListQuery(orderId);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (data) {
      dispatch(AnnualSupportChargeActions.setAll(data.annualSupportCharges));
    }
  }, [data, dispatch]);

  const annualSupportChargeList = useAppSelector((state) => selectAllAnnualSupportCharge(state));

  return { annualSupportChargeList, ...flags };
};
