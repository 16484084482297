import React, { useMemo } from 'react';
import { Control, Controller, FieldValues, Path, PathValue, UseControllerProps } from 'react-hook-form';
import { Autocomplete, AutocompleteProps, ListItem, TextField } from '@mui/material';
import { IBackendLabelOption } from '@features/backend-label/backend-label.type';
import { NullOption } from '@utils/null.option';

type Props<
  T extends FieldValues,
  V extends IBackendLabelOption<PathValue<T, Path<T>>>,
  M extends boolean | undefined,
> = {
  control: Control<T>;
  name: Path<T>;
  rules?: UseControllerProps<T>['rules'];
  label: string;
  required?: boolean;
  isLoading?: boolean;
  isNullAvailable?: boolean;
  filterPredicate?: (option: V) => boolean;
} & Pick<
  AutocompleteProps<V, M, false, false>,
  | 'disableClearable'
  | 'noOptionsText'
  | 'disabled'
  | 'groupBy'
  | 'getOptionDisabled'
  | 'options'
  | 'multiple'
  | 'filterOptions'
>;

export const ControlledAutocomplete: <
  T extends FieldValues,
  V extends IBackendLabelOption<PathValue<T, Path<T>>>,
  M extends boolean | undefined,
>(
  props: Props<T, V, M>,
) => JSX.Element = ({
  control,
  name,
  label,
  options: opts,
  required,
  rules,
  isLoading = false,
  multiple = false,
  isNullAvailable = false,
  filterPredicate,
  ...autocompleteProps
}) => {
  // TODO think about split props to controller slots, autocomplete slots and input slots

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore cast type that unable be passed legally
  const options: V[] = useMemo(() => (isNullAvailable ? [NullOption, ...opts] : opts), [isNullAvailable, opts]);

  if (filterPredicate) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore cast type that unable be passed legally
    autocompleteProps.filterOptions = (options, state): V[] =>
      options.filter(
        (option) => filterPredicate(option) && option.label.toLowerCase().includes(state.inputValue.toLowerCase()),
      );
  }

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, value }, fieldState }): JSX.Element =>
        multiple ? (
          <Autocomplete
            {...autocompleteProps}
            fullWidth
            multiple
            value={options.filter((option) => value.includes(option.value))}
            onChange={(_, newValue): void => onChange(newValue.map((option) => option.value))}
            options={options}
            renderInput={(params): JSX.Element => (
              <TextField
                {...params}
                required={required}
                label={label}
                error={Boolean(fieldState.error)}
                helperText={fieldState.error?.message}
              />
            )}
            renderOption={(props, option): JSX.Element => (
              <ListItem {...props} key={option.value?.toString()}>
                {option.label}
              </ListItem>
            )}
            loading={isLoading}
            size="small"
          />
        ) : (
          <Autocomplete
            {...autocompleteProps}
            fullWidth
            value={options.find((option) => option.value === value) ?? null}
            onChange={(_, newValue): void => onChange(newValue?.value ?? null)}
            options={options}
            renderInput={(params): JSX.Element => (
              <TextField
                {...params}
                required={required}
                label={label}
                error={Boolean(fieldState.error)}
                helperText={fieldState.error?.message}
              />
            )}
            renderOption={(props, option): JSX.Element => (
              <ListItem {...props} key={option.value?.toString()}>
                {option.label}
              </ListItem>
            )}
            loading={isLoading}
            size="small"
          />
        )
      }
    />
  );
};
