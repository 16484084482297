import { appQueryFactory } from '@api/api-slice';
import { EndpointUrlBuilder } from '@api/endpoints';
import { IPaginable, PaginationArgs } from '@features/pagination/paginable.interface';
import { validatePagination } from '@features/pagination/spec/pagination.spec';
import { TransportableModel } from '@models/transportable.type';
import { ISessionInvoiceSummary, ISessionInvoiceSummaryDTO } from '../session-invoice-summary.interface';
import { validateFilterSessionInvoiceSummary } from './spec/validate-session-invoice-summaries.spec';
import { ApiModel } from './form-config';
import { FinanceParams } from '@features/bulk-invoicing/finance-params.type';
import { enhanceWithInvoiceSummaryType } from '@features/bulk-invoicing/enhance-with-invoice-summary-type';

export type QueryParams = TransportableModel<ApiModel & PaginationArgs & FinanceParams>;
export type SessionInvoiceSummariesResponse = IPaginable<ISessionInvoiceSummary>;
export const buildGetSessionInvoiceSummaries = appQueryFactory<QueryParams, SessionInvoiceSummariesResponse>({
  query: (params) => ({
    url: EndpointUrlBuilder.getSessionInvoiceSummaries(),
    method: 'GET',
    params,
  }),
  transformResponse(response: IPaginable<ISessionInvoiceSummaryDTO>): IPaginable<ISessionInvoiceSummary> {
    validateFilterSessionInvoiceSummary(response.entries, EndpointUrlBuilder.getAppointmentInvoiceSummaries());
    validatePagination(response, EndpointUrlBuilder.getAppointmentInvoiceSummaries());
    return {
      pagination: response.pagination,
      entries: response.entries.map((entry) => enhanceWithInvoiceSummaryType<ISessionInvoiceSummary>(entry, 'Session')),
    };
  },
});
