import { appMutationFactory } from '@api/api-slice';
import { EndpointUrlBuilder } from '@api/endpoints';
import { modelToSnakeCase } from '@utils/model-to-snake-case';
import { BulkUpdatePayload, IBulkUpdateResponse } from '../types';

export const buildBulkGenerateEquipmentXeroInvoice = appMutationFactory<BulkUpdatePayload, IBulkUpdateResponse>({
  query: (payload) => ({
    url: EndpointUrlBuilder.bulkGenerateEquipmentXeroInvoice(),
    method: 'POST',
    data: modelToSnakeCase(payload),
  }),
});

export const buildBulkSendEquipmentInvoice = appMutationFactory<BulkUpdatePayload, IBulkUpdateResponse>({
  query: (payload) => ({
    url: EndpointUrlBuilder.bulkSendEquipmentInvoice(),
    method: 'POST',
    data: modelToSnakeCase(payload),
  }),
});
