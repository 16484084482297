import { useState } from 'react';

export function useCachedState<T>(initialState: T): [T, React.Dispatch<React.SetStateAction<T>>] {
  const [cachedState, internalSetCachedState] = useState(initialState);

  const setCachedState: React.Dispatch<React.SetStateAction<T>> = (newState) => {
    if (newState !== cachedState) {
      internalSetCachedState(newState);
    }
  };

  return [cachedState, setCachedState];
}
