import React from 'react';
import { Stack, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { AppDataGrid } from '@components/AppDataGrid';
import { AppDrawer } from '@components/AppDrawer';
import { useAppSelector } from '@store/use-app-selector';
import { AddSupplierInvoices } from './AddSupplierInvoices';
import { SendEInvoices } from './SendEInvoices';
import { GenerateXeroInvoices } from './GenerateXeroInvoices';
import { selectActiveWithResults } from './generic-invoice-summary.slice';
import { BulkActionTypeType, BulkActionTypes } from './bulk-action-type.type';
import { InvoiceSummaryType } from './invoice-summary-type.type';
import { MutationsByInvoiceSummaryAndBulkAction } from './mutations-by-invoice-summary-and-bulk-action';

type Props = {
  bulkActionType: BulkActionTypeType | null;
  invoiceSummaryType: InvoiceSummaryType;
  onClose: () => void;
  columns: GridColDef[];
};

export const BulkInvoicingDrawer: React.FC<Props> = ({ bulkActionType, invoiceSummaryType, onClose, columns }) => {
  const rows = useAppSelector((state) => selectActiveWithResults(state));
  const title = bulkActionType;

  const renderAction = (): JSX.Element | null => {
    switch (bulkActionType) {
      case BulkActionTypes.AddSupplierInvoice: {
        const mutation = MutationsByInvoiceSummaryAndBulkAction[invoiceSummaryType][bulkActionType];
        return mutation && <AddSupplierInvoices mutationHook={mutation} />;
      }

      case BulkActionTypes.GenerateXeroInvoice:
        return (
          <>
            <GenerateXeroInvoices
              mutationHook={MutationsByInvoiceSummaryAndBulkAction[invoiceSummaryType][bulkActionType]}
            />
            <SendEInvoices
              mutationHook={MutationsByInvoiceSummaryAndBulkAction[invoiceSummaryType][BulkActionTypes.SendEInvoice]}
            />
          </>
        );

      case BulkActionTypes.SendEInvoice:
        return (
          <SendEInvoices mutationHook={MutationsByInvoiceSummaryAndBulkAction[invoiceSummaryType][bulkActionType]} />
        );

      default:
        return null;
    }
  };

  return (
    <AppDrawer open={bulkActionType !== null} onClose={onClose} width="92vw">
      <Typography variant="h4" component="h1">
        {title}
      </Typography>
      <Stack direction="row" spacing={1}>
        {renderAction()}
      </Stack>
      <AppDataGrid rows={rows} columns={columns} sx={{ mt: 1 }} hideFooter />
    </AppDrawer>
  );
};
