import { useCallback } from 'react';
import { useAppDispatch } from '@store/use-app-dispatch';
import { RequestStatusFlags } from '@utils/merge-request-status-flags';
import { GenericInvoiceSummaryActions } from './generic-invoice-summary.slice';
import { UseBulkActionMutation, isSuccessRow } from './types';
import { showResultSnackbar } from './show-result-snackbar';
import { isErrorResponse } from '@api/error-response.interface';

type HookResult<Payload> = {
  handleSubmit: (payload: Payload) => Promise<void>;
} & RequestStatusFlags;

export function useBulkUpdate<Payload>(mutationHook: UseBulkActionMutation<Payload>): HookResult<Payload> {
  const [mutation, flags] = mutationHook();
  const dispatch = useAppDispatch();

  const handleSubmit = useCallback(
    async (payload: Payload): Promise<void> => {
      const response = await mutation(payload).unwrap();
      if (!isErrorResponse(response)) {
        const acceptedChanges = response.results.filter(isSuccessRow);
        dispatch(GenericInvoiceSummaryActions.updateMany(acceptedChanges));
        dispatch(GenericInvoiceSummaryActions.setResults(response.results));
        showResultSnackbar(response.results);
      }
    },
    [dispatch, mutation],
  );

  return {
    ...flags,
    handleSubmit,
  };
}
