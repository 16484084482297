import { enqueueSnackbar } from 'notistack';
import { BulkActionRowResult, isSuccessRow } from './types';

export function showResultSnackbar(entries: BulkActionRowResult[]): void {
  const hasErrors = entries.some((item) => !isSuccessRow(item));
  const hasSuccess = entries.some((item) => isSuccessRow(item));
  if (hasErrors) {
    if (hasSuccess) {
      enqueueSnackbar('Some rows rejected', { variant: 'warning' });
    } else {
      enqueueSnackbar('All rows rejected', { variant: 'error' });
    }
  } else {
    enqueueSnackbar('All rows accepted', { variant: 'success' });
  }
}
