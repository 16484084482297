export const InvoiceSummaryTypes = {
  AnnualSupportCharge: 'AnnualSupportCharge',
  Appointment: 'Appointment',
  Equipment: 'Equipment',
  Session: 'Session',
} as const;

type Keys = keyof typeof InvoiceSummaryTypes;

export type InvoiceSummaryType = (typeof InvoiceSummaryTypes)[Keys];
