export const Routes = {
  login: '/login',
  reset: '/reset',
  root: '/',

  dashboard: '/dashboard',
  tasks: '/my-tasks',

  orderSummaries: '/orders',
  deliveryCalendar: '/delivery-calendar',
  allocations: '/allocations',
  sessions: '/sessions',
  tutors: '/tutors',

  support: '/support',
  insurance: '/insurance',
  itf: '/itf',

  products: '/products',
  quotes: '/quotes',
  kpi: '/kpi',
  assessors: '/assessors',
  fundingBodies: '/funding-bodies',

  settings: '/settings',
  notifications: '/notifications',

  customerOrders: '/customer',

  appointments: '/appointment-summaries',
  assessorUtilisation: '/assessor-utilisation',
  assessmentCentres: '/assessment-centres',

  myAvailability: '/my-availability',
  equipmentInvoicing: '/equipment-invoicing-dashboard',
  heps: '/heps',

  bulkInvoicing: '/bulk-invoicing',
  bulkAppointmentInvoicing: '/bulk-invoicing/appointment',
  bulkSessionInvoicing: '/bulk-invoicing/session',
  bulkEquipmentInvoicing: '/bulk-invoicing/equipment',
  bulkAnnualSupportChargeInvoicing: '/bulk-invoicing/annual-support-charge',
};

type Keys = keyof typeof Routes;

export type RouteType = (typeof Routes)[Keys];
