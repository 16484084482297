import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { IAnnualSupportCharge } from './annual-support-charge.interface';
import { RootState } from '@store/store';

const annualSupportChargeAdapter = createEntityAdapter<IAnnualSupportCharge>({
  selectId: (entry) => entry.id,
});

const annualSupportChargeSlice = createSlice({
  name: 'Annual Support Charge List',
  initialState: annualSupportChargeAdapter.getInitialState(),
  reducers: {
    setAll: annualSupportChargeAdapter.setAll,
    upsertOne: annualSupportChargeAdapter.upsertOne,
    upsertMany: annualSupportChargeAdapter.upsertMany,
    updateOne: annualSupportChargeAdapter.updateOne,
  },
});

export const AnnualSupportChargeActions = annualSupportChargeSlice.actions;
export const annualSupportChargeReducer = annualSupportChargeSlice.reducer;
export type AnnualSupportChargeActionType = typeof AnnualSupportChargeActions;

const { selectAll } = annualSupportChargeAdapter.getSelectors((state: RootState) => state.annualSupportCharge);

export const selectAllAnnualSupportCharge = selectAll;
