import { appQueryFactory } from '@api/api-slice';
import { EndpointUrlBuilder } from '@api/endpoints';
import { IPaginable, PaginationArgs } from '@features/pagination/paginable.interface';
import { TransportableModel } from '@models/transportable.type';
import { IAppointmentInvoiceSummary, IAppointmentInvoiceSummaryDTO } from '../appointment-invoice-summary.interface';
import { ApiModel } from './form-config';
import { validateFilterAppointmentInvoiceSummary } from './spec/validate-appointment-invoice-summaries.spec';
import { validatePagination } from '@features/pagination/spec/pagination.spec';
import { FinanceParams } from '@features/bulk-invoicing/finance-params.type';
import { enhanceWithInvoiceSummaryType } from '@features/bulk-invoicing/enhance-with-invoice-summary-type';

export type QueryParams = TransportableModel<ApiModel & PaginationArgs & FinanceParams>;
export type AppointmentInvoiceSummariesResponse = IPaginable<IAppointmentInvoiceSummary>;
export const buildGetAppointmentInvoiceSummaries = appQueryFactory<QueryParams, AppointmentInvoiceSummariesResponse>({
  query: (params) => ({
    url: EndpointUrlBuilder.getAppointmentInvoiceSummaries(),
    method: 'GET',
    params,
  }),
  transformResponse(response: IPaginable<IAppointmentInvoiceSummaryDTO>): IPaginable<IAppointmentInvoiceSummary> {
    validateFilterAppointmentInvoiceSummary(response.entries, EndpointUrlBuilder.getAppointmentInvoiceSummaries());
    validatePagination(response, EndpointUrlBuilder.getAppointmentInvoiceSummaries());
    return {
      pagination: response.pagination,
      entries: response.entries.map((entry) =>
        enhanceWithInvoiceSummaryType<IAppointmentInvoiceSummary>(entry, 'Appointment'),
      ),
    };
  },
});
