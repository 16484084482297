import { Describe, StructError, assert, boolean, enums, nullable, number, object, pattern, string } from 'superstruct';
import { AssessmentMethods } from '@features/order/needs-assessment/assessment-method.type';
import { IAppointmentInvoiceSummaryDTO } from '@features/bulk-invoicing/appointment/appointment-invoice-summary.interface';
import { handleFailure } from '@utils/superstruct/handle-failure';
import { ISODateTimeRegexp } from '@utils/dates/iso-string.type';
import { MoneyStruct } from '@utils/superstruct/money.struct';

const AppointmentInvoiceSummaryStruct: Describe<IAppointmentInvoiceSummaryDTO> = object({
  id: number(),
  orderId: number(),
  customerId: nullable(number()),
  assessmentMethod: nullable(enums(Object.values(AssessmentMethods))),
  appointmentDate: nullable(pattern(string(), ISODateTimeRegexp)),
  isCancelled: boolean(),
  cancellationReason: nullable(string()),
  isPaid: boolean(),
  funderInvoice: nullable(string()),
  supplierInvoice: nullable(string()),
  customerFullName: string(),
  customerCrn: string(),
  orderStatusId: number(),
  dipReference: string(),
  organisationId: number(),
  exVat: MoneyStruct,
});

export function validateFilterAppointmentInvoiceSummary(entries: IAppointmentInvoiceSummaryDTO[], url: string): void {
  for (const entry of entries) {
    try {
      assert(entry, AppointmentInvoiceSummaryStruct);
    } catch (error) {
      for (const failure of (error as StructError).failures()) {
        handleFailure(failure, url);
      }
    }
  }
}
