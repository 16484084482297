import React from 'react';
import { NavLink } from 'react-router-dom';
import { Link } from '@mui/material';
import { BackendLabel } from '@features/backend-label/BackendLabel';
import { QuoteStatusOptions } from '@features/quote/quote-status.type';
import { Routes } from '@routes/routes';
import { createColumnFactory } from '@utils/create-column.factory';
import { BooleanChip } from '@utils/render-boolean';
import { renderMoney } from '@utils/money/render-money';
import { renderEnum } from '@utils/enum/render-enum';
import { EmptyCell } from '@utils/empty-cell';
import { ResultCell } from '../ResultCell';
import { Result } from '../types';
import { IEquipmentInvoiceSummary } from './equipment-invoice-summary.interface';

type SummaryWithResult = IEquipmentInvoiceSummary & { result: Result | null };

const createEquipmentInvoiceSummaryColumn = createColumnFactory<IEquipmentInvoiceSummary>();

export const equipmentInvoiceColumns = [
  createEquipmentInvoiceSummaryColumn('id', {
    width: 80,
    headerName: 'IDs',
    description: 'CustomerId/OrderId',
    renderCell: ({ row: summary }) => (
      <Link
        key={summary.id}
        component={NavLink}
        to={`${Routes.orderSummaries}/${summary.customerId}/${summary.orderId}`}
      >
        {summary.customerId}/{summary.orderId}
      </Link>
    ),
  }),
  createEquipmentInvoiceSummaryColumn('organisationId', {
    headerName: 'Organisation',
    flex: 1,
    renderCell: ({ row }) => <BackendLabel value={row.organisationId} optionKey="organisationOptions" />,
    optionKey: 'organisationOptions',
  }),
  createEquipmentInvoiceSummaryColumn('orderTypeId', {
    headerName: 'Order Type',
    flex: 1,
    renderCell: ({ row }) => <BackendLabel value={row.orderTypeId} optionKey="orderTypeNameOptions" />,
    optionKey: 'orderTypeNameOptions',
  }),
  createEquipmentInvoiceSummaryColumn('quoteId', {
    headerName: 'Quote Number',
    flex: 1,
  }),
  createEquipmentInvoiceSummaryColumn('quoteStatus', {
    headerName: 'Quote Status',
    width: 110,
    renderCell: ({ row }) => renderEnum(row.quoteStatus, QuoteStatusOptions),
  }),
  createEquipmentInvoiceSummaryColumn('exVat', {
    headerName: 'Quote Ex Vat',
    flex: 1,
    align: 'right',
    headerAlign: 'right',
    cellClassName: 'number',
    renderCell: ({ row }) => renderMoney(row.exVat),
  }),
  createEquipmentInvoiceSummaryColumn('inclVat', {
    headerName: 'Quote Incl Vat',
    flex: 1,
    align: 'right',
    headerAlign: 'right',
    cellClassName: 'number',
    renderCell: ({ row }) => renderMoney(row.inclVat),
  }),
  createEquipmentInvoiceSummaryColumn('isContributePayable', {
    headerName: 'DSA Contribution',
    flex: 1,
    align: 'right',
    headerAlign: 'right',
    cellClassName: 'number',
    renderCell: ({ row }) => (row.isContributePayable ? renderMoney(200) : EmptyCell),
  }),
  createEquipmentInvoiceSummaryColumn('dsa2ApprovedAmount', {
    headerName: 'Approved Amount',
    flex: 1,
    align: 'right',
    headerAlign: 'right',
    cellClassName: 'number',
    renderCell: ({ row }) => renderMoney(row.dsa2ApprovedAmount),
  }),
  createEquipmentInvoiceSummaryColumn('upgradePrice', {
    headerName: 'Upgrade Price',
    flex: 1,
    align: 'right',
    headerAlign: 'right',
    cellClassName: 'number',
    renderCell: ({ row }) => renderMoney(row.upgradePrice),
  }),
  createEquipmentInvoiceSummaryColumn('totalAmount', {
    headerName: 'Total Value',
    flex: 1,
    align: 'right',
    headerAlign: 'right',
    cellClassName: 'number',
    renderCell: ({ row }) => renderMoney(row.totalAmount),
  }),
  createEquipmentInvoiceSummaryColumn('isUpgradedEquipment', {
    headerName: 'Upgraded',
    width: 70,
    renderCell: ({ row }) => <BooleanChip value={row.isUpgradedEquipment} />,
  }),
  createEquipmentInvoiceSummaryColumn('isMatchesDsa2', {
    headerName: 'Matches',
    description: 'Matches DSA2',
    width: 70,
    renderCell: ({ row }) => <BooleanChip value={row.isMatchesDsa2} />,
  }),
  createEquipmentInvoiceSummaryColumn('funderInvoice', { headerName: 'Supplier Invoice', width: 110 }),
  createEquipmentInvoiceSummaryColumn('dipReference', {
    headerName: 'DIP',
    width: 70,
  }),
];

// quote total incl vat

const createColumnWithResult = createColumnFactory<SummaryWithResult>();

export const equipmentInvoiceColumnsWithResult = equipmentInvoiceColumns.concat(
  createColumnWithResult('result', {
    headerName: 'Result',
    width: 120,
    renderCell: ({ row }) => <ResultCell result={row.result} />,
  }),
);
