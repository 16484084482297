import React from 'react';
import { AppLoadingButton } from '@components/AppLoadingButton';
import { useAppSelector } from '@store/use-app-selector';
import { selectActiveIds } from './generic-invoice-summary.slice';
import { useBulkUpdate } from './use-bulk-update';
import { BulkUpdatePayload, UseBulkActionMutation } from './types';

type Props = {
  mutationHook: UseBulkActionMutation<BulkUpdatePayload>;
};

export const GenerateXeroInvoices: React.FC<Props> = ({ mutationHook }) => {
  const { handleSubmit, isLoading } = useBulkUpdate(mutationHook);
  const ids = useAppSelector((state) => selectActiveIds(state));

  const handleClick = (): void => {
    handleSubmit({ ids });
  };

  return (
    <AppLoadingButton isLoading={isLoading} onClick={handleClick}>
      Generate Xero Invoices
    </AppLoadingButton>
  );
};
