import React, { useState } from 'react';
import { Stack, TextField } from '@mui/material';
import { AppLoadingButton } from '@components/AppLoadingButton';
import { useAppSelector } from '@store/use-app-selector';
import { selectActiveIds } from './generic-invoice-summary.slice';
import { useBulkUpdate } from './use-bulk-update';
import { BulkAddSupplierInvoicePayload, UseBulkActionMutation } from './types';

type Props = {
  mutationHook: UseBulkActionMutation<BulkAddSupplierInvoicePayload>;
};

export const AddSupplierInvoices: React.FC<Props> = ({ mutationHook }) => {
  const { handleSubmit, isLoading } = useBulkUpdate(mutationHook);
  const ids = useAppSelector((state) => selectActiveIds(state));
  const [supplierInvoice, setSupplierInvoice] = useState('');

  const handleClick = (): void => {
    handleSubmit({ ids, supplierInvoice });
  };

  return (
    <Stack direction="row" spacing={2}>
      <TextField
        variant="outlined"
        value={supplierInvoice}
        onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
          setSupplierInvoice(event.target.value);
        }}
      />
      <AppLoadingButton isLoading={isLoading} disabled={supplierInvoice === ''} onClick={handleClick}>
        Add Invoice
      </AppLoadingButton>
    </Stack>
  );
};
