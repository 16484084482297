import { Describe, StructError, assert, boolean, nullable, number, object, pattern, string } from 'superstruct';
import { ISessionInvoiceSummaryDTO } from '@features/bulk-invoicing/session/session-invoice-summary.interface';
import { ISODateTimeRegexp } from '@utils/dates/iso-string.type';
import { handleFailure } from '@utils/superstruct/handle-failure';
import { MoneyStruct } from '@utils/superstruct/money.struct';

const SessionInvoiceSummaryStruct: Describe<ISessionInvoiceSummaryDTO> = object({
  id: number(),
  startMoment: pattern(string(), ISODateTimeRegexp),
  duration: number(),
  isCancelled: boolean(),
  cancellationReason: nullable(string()),
  isPaid: boolean(),
  funderInvoice: nullable(string()),
  customerId: number(),
  orderId: number(),
  customerFullName: string(),
  tutorFullName: string(),
  customerCrn: string(),
  hourlyRate: MoneyStruct,
  exVat: MoneyStruct,
  dipReference: nullable(string()),
  organisationId: number(),
  timesheetPath: nullable(string()),
  supplierInvoice: nullable(string()),
});

export function validateFilterSessionInvoiceSummary(entries: ISessionInvoiceSummaryDTO[], url: string): void {
  for (const entry of entries) {
    try {
      assert(entry, SessionInvoiceSummaryStruct);
    } catch (error) {
      for (const failure of (error as StructError).failures()) {
        handleFailure(failure, url);
      }
    }
  }
}
