import { Update } from '@reduxjs/toolkit';
import { MutationDefinition } from '@reduxjs/toolkit/dist/query';
import { UseMutation } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { AxiosBaseQuery } from '@api/http-query-client';
import { IInvoiceSummary } from './invoice-summary.interface';
import { IErrorResponse } from '@api/error-response.interface';

export type BulkUpdatePayload = {
  ids: number[];
};

export type BulkAddSupplierInvoicePayload = BulkUpdatePayload & {
  supplierInvoice: string;
};

type IBulkUpdateSuccessResponse = {
  results: BulkActionRowResult[];
};

export type IBulkUpdateResponse = IBulkUpdateSuccessResponse | IErrorResponse;

export type UseBulkActionMutation<Payload> = UseMutation<
  MutationDefinition<Payload, AxiosBaseQuery, never, IBulkUpdateResponse, 'api'>
>;

export type Result = {
  isSuccess: boolean;
  message: string | null;
};

type SuccessRow = { id: number; result: Result } & Update<IInvoiceSummary>;

type FailureRow = { id: number; result: Result };

export type BulkActionRowResult = SuccessRow | FailureRow;

export function isSuccessRow(row: BulkActionRowResult): row is SuccessRow {
  return row.result.isSuccess;
}

export type IInvoiceSummaryWithResult = IInvoiceSummary & {
  result: Result | null;
};
