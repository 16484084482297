import { IOrderTechnical } from '@features/order/technical/order-technical.interface';
import { QuoteStatusType } from '@features/quote/quote-status.type';
import { Money } from '@utils/money/money.type';
import { InvoiceSummaryTypes } from '../invoice-summary-type.type';
import { IInvoiceSummary } from '../invoice-summary.interface';

type SharedOrderTechnicalFields = Pick<
  IOrderTechnical,
  | 'id'
  | 'organisationId'
  | 'orderTypeId'
  | 'quoteId'
  | 'isUpgradedEquipment'
  | 'dsa2ApprovedAmount'
  | 'upgradePrice'
  | 'isContributePayable'
>;

export interface IEquipmentInvoiceSummary extends SharedOrderTechnicalFields {
  invoiceSummaryType: typeof InvoiceSummaryTypes.Equipment;
  quoteStatus: QuoteStatusType;
  exVat: Money;
  inclVat: Money;
  customerId: number;
  orderId: number;
  totalAmount: Money;
  isMatchesDsa2: boolean;
  funderInvoice: string | null;
  dipReference: string | null;
}

export type IEquipmentInvoiceSummaryDTO = Omit<IEquipmentInvoiceSummary, 'invoiceSummaryType'>;

export function isEquipmentInvoice(invoice: IInvoiceSummary): invoice is IEquipmentInvoiceSummary {
  return invoice.invoiceSummaryType === InvoiceSummaryTypes.Equipment;
}
