import React, { FC } from 'react';
import { AppLoadingButton } from '@components/AppLoadingButton';
import { ElectronicInvoice } from '@features/electronic-invoice/ElectronicInvoice';
import { IOrderAnnualSupportCharge } from '../annual-support-charge-order.interface';
import { IAnnualSupportCharge } from './annual-support-charge.interface';
import { useGenerateAnnualSupportChargeElectronicInvoice } from './use-generate-asc-electronic-invoice';
import { useRefreshAnnualSupportChargeElectronicInvoice } from './use-refresh-asc-electronic-invoice';
import { useSaveAnnualSupportChargeElectronicInvoice } from './use-save-asc-electronic-invoice';
import { useSendAnnualSupportChargeElectronicInvoice } from './use-send-asc-electronic-invoice';

type Props = {
  order: IOrderAnnualSupportCharge;
  annualSupportCharge: IAnnualSupportCharge;
};

export const AnnualSupportChargeElectronicInvoice: FC<Props> = ({ order, annualSupportCharge }) => {
  const { id } = annualSupportCharge;
  const { generateAnnualSupportChargeElectronicInvoice, isLoading: isGenerating } =
    useGenerateAnnualSupportChargeElectronicInvoice();
  const { saveAnnualSupportChargeElectronicInvoice, isLoading: isLoadingSave } =
    useSaveAnnualSupportChargeElectronicInvoice(annualSupportCharge, order.id);
  const { refreshAnnualSupportChargeElectronicInvoice, isLoading: isLoadingRefresh } =
    useRefreshAnnualSupportChargeElectronicInvoice();
  const { sendAnnualSupportChargeElectronicInvoice, isLoading: isLoadingSend } =
    useSendAnnualSupportChargeElectronicInvoice();

  async function handleSave(electronicInvoice: string | null): Promise<void> {
    if (electronicInvoice) {
      saveAnnualSupportChargeElectronicInvoice(electronicInvoice);
    }
  }

  async function handleRefresh(): Promise<void> {
    refreshAnnualSupportChargeElectronicInvoice(id);
  }

  async function handleSend(): Promise<void> {
    sendAnnualSupportChargeElectronicInvoice(id);
  }

  async function handleGenerate(): Promise<void> {
    generateAnnualSupportChargeElectronicInvoice(id);
  }

  const isLoading = isLoadingSave || isLoadingRefresh || isLoadingSend;

  if (!annualSupportCharge.funderInvoice) {
    return (
      <AppLoadingButton isLoading={isGenerating} onClick={handleGenerate} variant="outlined" size="small">
        Generate
      </AppLoadingButton>
    );
  }

  return (
    <ElectronicInvoice
      invoice={annualSupportCharge.electronicInvoice}
      invoiceLogs={annualSupportCharge.electronicInvoiceLogs}
      isLoading={isLoading}
      buttonSize="small"
      handleSave={handleSave}
      handleRefresh={handleRefresh}
      handleSend={handleSend}
    />
  );
};
