import { IAnnualSupportCharge } from '@features/order/annual-support-charge/annual-support-charge-list/annual-support-charge.interface';
import { InvoiceSummaryTypes } from '../invoice-summary-type.type';
import { IInvoiceSummary } from '../invoice-summary.interface';

type SharedAnnualSupportChargeFields = Pick<
  IAnnualSupportCharge,
  | 'id'
  | 'numberOfSupportYear'
  | 'billingDate'
  | 'isCancelled'
  | 'cancellationReason'
  | 'isPaid'
  | 'annualSupportInclVat'
  | 'annualSupportExVat'
  | 'funderInvoice'
>;

export interface IAnnualSupportChargeInvoiceSummary extends SharedAnnualSupportChargeFields {
  invoiceSummaryType: typeof InvoiceSummaryTypes.AnnualSupportCharge;
  customerId: number;
  orderId: number;
  equipmentTypeId: number;
  dipReference: string | null;
}

export type IAnnualSupportChargeInvoiceSummaryDTO = Omit<IAnnualSupportChargeInvoiceSummary, 'invoiceSummaryType'>;

export function isAnnualSupportChargeInvoice(invoice: IInvoiceSummary): invoice is IAnnualSupportChargeInvoiceSummary {
  return invoice.invoiceSummaryType === InvoiceSummaryTypes.AnnualSupportCharge;
}
